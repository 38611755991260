import { createSlice } from "@reduxjs/toolkit";

const StudentSlice = createSlice({
  name: "students",
  initialState: {
    students: [],
    collapseStudents: [],
    student: {},
    studentSubscriptions: [],
    studentPayments: [],
  },
  reducers: {
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    setCollapseStudents: (state, action) => {
      state.collapseStudents = action.payload;
    },
    setStudent: (state, action) => {
      state.student = action.payload;
    },
    setStudentSubscriptions: (state, action) => {
      state.studentSubscriptions = action.payload;
    },
    setStudentPayments: (state, action) => {
      state.studentPayments = action.payload;
    },
  },
});

export const {
  setStudents,
  setStudent,
  setStudentSubscriptions,
  setStudentPayments,
  setCollapseStudents,
} = StudentSlice.actions;

export default StudentSlice.reducer;
