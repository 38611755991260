import { createSlice } from "@reduxjs/toolkit";

const ClassSlice = createSlice({
  name: "classes",
  initialState: {
    classes: [],
    class: {},
    class_materials: [],
  },
  reducers: {
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
    setClass: (state, action) => {
      state.class = action.payload;
    },
    setClassMaterials: (state, action) => {
      state.class_materials = action.payload;
    },
  },
});

export const {
  setClasses,
  setClass,
  setClassMaterials,
} = ClassSlice.actions;

export default ClassSlice.reducer;
