import { lazy } from "react";
import WithLazyComponent from "../shared/components/WithLazyComponent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import i18next from "../i18n/config";
import GroupsIcon from "@mui/icons-material/Groups";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TuneIcon from '@mui/icons-material/Tune';

const DashboardIndex = WithLazyComponent(
  lazy(() => import("./Dashboard/index"))
);
const MaterialIndex = WithLazyComponent(
  lazy(() => import("./Materials/index"))
);
const ClassIndex = WithLazyComponent(lazy(() => import("./Classes/index")));
const GalleryIndex = WithLazyComponent(lazy(() => import("./Gallery/index")));
const ContentIndex = WithLazyComponent(lazy(() => import("./Contents/index")));
const StudentIndex = WithLazyComponent(lazy(() => import("./Students/index")));
const SettingIndex = WithLazyComponent(lazy(() => import("./Settings/index")));

const AdminRoutes = [
 
  {
    path: "/admin/dashboard",
    icon: LeaderboardIcon,
    label: "dashboard",
    children: [
      { index: true, element: DashboardIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/gallery",
    icon: PhotoLibraryIcon,
    label: "gallery",
    children: [
      { index: true, element: GalleryIndex },
      // { path: "/admin/gallery/create", element: GalleryIndex },
      // { path: "/admin/gallery/view/:id", element: GalleryIndex },
    ],
  },
  {
    path: "/admin/materials",
    icon: LibraryBooksIcon,
    label: "materials",
    children: [
      { index: true, element: MaterialIndex },
      { path: "/admin/materials/create", element: MaterialIndex },
      { path: "/admin/materials/view/:id", element: MaterialIndex },
    ],
  },
 
  {
    path: "/admin/classes",
    icon: SchoolIcon,
    label: "classes",
    children: [
      { index: true, element: ClassIndex },
      { path: "/admin/classes/create", element: ClassIndex },
      { path: "/admin/classes/view/:id", element: ClassIndex },
      { path: "/admin/classes/view-materials/:id", element: ClassIndex },
    ],
  },
  {
    path: "/admin/contents",
    icon: MenuBookIcon,
    label: "content",
    children: [
      { index: true, element: ContentIndex },
      { path: "/admin/contents/create-lesson/:id", element: ContentIndex },
      { path: "/admin/contents/view/:id", element: ContentIndex },
      { path: "/admin/contents/edit-lesson/:lesson_id", element: ContentIndex },
    ],
  },
  {
    path: "/admin/students",
    icon: PeopleAltIcon,
    label: "students",
    children: [
      { index: true, element: StudentIndex },
      { path: "/admin/students/create", element: StudentIndex },
      { path: "/admin/students/view/:id", element: StudentIndex },
      { path: "/admin/students/subsriptions/:id", element: StudentIndex },
      { path: "/admin/students/payments/:id", element: StudentIndex },
    ],
  },
  {
    path: "/admin/settings",
    icon: TuneIcon,
    label: "settings",
    children: [
      { index: true, element: SettingIndex },
     
    ],
  },

  // {
  //   path: "*",
  //   // element: HomeIndex,
  // },
];

export default AdminRoutes;
