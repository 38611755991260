import { lazy } from "react";
import WithLazyComponent from "../shared/components/WithLazyComponent";

const LandingPage = WithLazyComponent(lazy(() => import("./LandingPage/index")));
const LogIndex = WithLazyComponent(lazy(() => import("./Register/index")));
const SignIndex = WithLazyComponent(lazy(() => import("./Register/index")));
const ProfileIndex = WithLazyComponent(lazy(() => import("./Profile/index")));
const ForgetPassword = WithLazyComponent(
  lazy(() => import("./Register/index"))
);

export const SiteRoutes = [
  {
    path: "/home",
    // element: <Layout />,
    children: [
      { index: true, element: LandingPage },
      //   { path: 'create', element: Create },
    ],
  },
  {
    path: "/login",
    element: LogIndex,
  },
  // {
  //   path: "/signup",
  //   element: SignIndex,
  // },
  {
    path: "/profile",
    element: ProfileIndex,
  },
  { path: "/forget-password", element: ForgetPassword },
  { path: "/reset-password", element: ForgetPassword },
  {
    path: "/change-password",
    element: ProfileIndex,
  },
 

  {
    path: "*",
    element: LandingPage,
  },
];
