import { createSlice } from "@reduxjs/toolkit";

const ContentSlice = createSlice({
  name: "contents",
  initialState: {
    contents: [],
    lessonContent: {},
    contentLessons: [],
  },
  reducers: {
    setContents: (state, action) => {
      state.contents = action.payload;
    },
    setLessonContent: (state, action) => {
      state.lessonContent = action.payload;
    },
    setContentLessons: (state, action) => {
      state.contentLessons = action.payload;
    },
  },
});

export const { setContents, setLessonContent, setContentLessons } =
  ContentSlice.actions;

export default ContentSlice.reducer;
