import { createSlice } from "@reduxjs/toolkit";

const MaterialSlice = createSlice({
  name: "materials",
  initialState: {
    materials: [],
    material: {},
  },
  reducers: {
    setMaterials: (state, action) => {
      state.materials = action.payload;
    },
    setMaterial: (state, action) => {
      state.material = action.payload;
    },
  },
});

export const { setMaterials, setMaterial } = MaterialSlice.actions;

export default MaterialSlice.reducer;
