import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Reducers/AuthSlice";
import ContentSlice from "./Reducers/ContentSlice";
import ClassSlice from "./Reducers/ClassSlice";
import MaterialSlice from "./Reducers/MaterialSlice";
import StudentSlice from "./Reducers/StudentSlice";
import SettingSlice from "./Reducers/SettingSlice";
import DashboardSlice from "./Reducers/DashboardSlice";
import GallerySlice from './Reducers/GallerySlice'

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};
const preloadedState = loadState();
const store = configureStore({
  reducer: {
    auth: AuthSlice,
    contents: ContentSlice,
    classes: ClassSlice,
    materials: MaterialSlice,
    students: StudentSlice,
    settings: SettingSlice,
    dashboard: DashboardSlice,
    gallery: GallerySlice,
  },
  preloadedState,
  // enhancers: [window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()],
});
export default store;
store.subscribe(() => {
  saveState(store.getState());
});
